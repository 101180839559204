import { Container, ListItems, Item, HeaderInnerContainer } from './styles'
import { updateTab } from '@/store/actions/home'
import { setUserWallet } from '@/store/actions/user'
import * as homeSelector from '@/store/selectors/home'
import { TABTYPES } from '@/store/types/home'
import { BackButton } from '@/styles/styles.home'
import { getUserWallet } from '@/utils/api-ssr'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

const TopNavigation = ({ isTopNavVisible, genrePage }) => {
    const navElement = useRef(false)
    const activeTab = useSelector(homeSelector._activeTab)
    const dispatch = useDispatch()
    const { data: session } = useSession()
    const router = useRouter()

    useEffect(() => {
        const getUserWalletDetails = async () => {
            const userWallet = await getUserWallet(session?.token)
            dispatch(setUserWallet(userWallet?.data))
        }

        if (session?.userName) {
            getUserWalletDetails()
        }
    }, [dispatch, session?.token, session?.userName])

    const handleUpdateTab = (currentTab) => {
        dispatch(updateTab(currentTab))
    }

    return (
        <Container ref={navElement} genrePage={genrePage}>
            {isTopNavVisible && (
                <HeaderInnerContainer>
                    {router.pathname === '/coin-detail' ? (
                        <BackButton
                            loading="lazy"
                            src={'/img/back-button-24.svg'}
                            onClick={() => router.back()}
                        />
                    ) : (
                        <ListItems>
                            <Link href={`/`} passHref prefetch={false}>
                                <Item
                                    active={
                                        activeTab === TABTYPES.FOR_YOU
                                            ? true
                                            : false
                                    }
                                    onClick={() => {
                                        handleUpdateTab(TABTYPES.FOR_YOU)
                                    }}
                                >
                                    Buat Kamu
                                </Item>
                            </Link>

                            <Link href={`/podcast`} passHref prefetch={false}>
                                <Item
                                    active={
                                        activeTab === TABTYPES.PODCAST
                                            ? true
                                            : false
                                    }
                                    onClick={() => {
                                        handleUpdateTab(TABTYPES.PODCAST)
                                    }}
                                >
                                    Podcast
                                </Item>
                            </Link>
                            <Link
                                href={`/audioseries`}
                                passHref
                                prefetch={false}
                            >
                                <Item
                                    active={
                                        activeTab === TABTYPES.ORIGINAL_SERIES
                                            ? true
                                            : false
                                    }
                                    onClick={() => {
                                        handleUpdateTab(
                                            TABTYPES.ORIGINAL_SERIES
                                        )
                                    }}
                                >
                                    Audioseries
                                </Item>
                            </Link>
                            <Link href={`/radio`} passHref prefetch={false}>
                                <Item
                                    active={
                                        activeTab === TABTYPES.RADIO
                                            ? true
                                            : false
                                    }
                                    onClick={() => {
                                        handleUpdateTab(TABTYPES.RADIO)
                                    }}
                                >
                                    Radio
                                </Item>
                            </Link>
                            <Link href={`/audiobook`} passHref prefetch={false}>
                                <Item
                                    active={
                                        activeTab === TABTYPES.AUDIOBOOK
                                            ? true
                                            : false
                                    }
                                    onClick={() => {
                                        handleUpdateTab(TABTYPES.AUDIOBOOK)
                                    }}
                                >
                                    Audiobook
                                </Item>
                            </Link>
                        </ListItems>
                    )}
                </HeaderInnerContainer>
            )}
        </Container>
    )
}

export default TopNavigation
