export const _activeTab = (state) => state?.homeReducer?.activeTab
export const _activeNav = (state) => state?.homeReducer?.activeNav

// Segment Lists
export const _segmentList = (state) => state?.homeReducer?.segmentList
export const _homeSegmentList = (state) => state?.homeReducer?.homeSegmentList
export const _podcastSegmentList = (state) =>
    state?.homeReducer?.podcastSegmentList
export const _radioSegmentList = (state) => state?.homeReducer?.radioSegmentList
export const _audiobookSegmentList = (state) =>
    state?.homeReducer?.audiobookSegmentList
export const _audioseriesSegmentList = (state) =>
    state?.homeReducer?.audioseriesSegmentList
export const _livestreamSegmentList = (state) =>
    state?.homeReducer?.livestreamSegmentList

// Segment Offsets
export const _homeOffset = (state) => state?.homeReducer?.homeOffset
export const _podcastOffset = (state) => state?.homeReducer?.podcastOffset
export const _radioOffset = (state) => state?.homeReducer?.radioOffset
export const _audiobookOffset = (state) => state?.homeReducer?.audiobookOffset
export const _audioseriesOffset = (state) =>
    state?.homeReducer?.audioseriesOffset
export const _livestreamOffset = (state) => state?.homeReducer?.livestreamOffset

export const _updateSegmentStatus = (state) =>
    state?.homeReducer?.fetchSegmentStatus

export const _totalSegmentCount = (state) =>
    state?.homeReducer?.totalSegmentCount

export const _getHomeBanner = (state) => state?.homeReducer?.banner.home
export const _getPodcastBanner = (state) => state?.homeReducer?.banner.podcast
export const _getAudiobookBanner = (state) =>
    state?.homeReducer?.banner.audiobook
export const _getAudioseriesBanner = (state) =>
    state?.homeReducer?.banner.audioseries

export const _updateBannerStatus = (state) =>
    state?.homeReducer?.fetchBannerStatus

export const _getSegmentDetail = (state) => state?.homeReducer?.segmentDetail

export const _updateSegmentDetailStatus = (state) =>
    state?.homeReducer?.fetchSegmentDetailStatus

export const _totalSegmentDetailCount = (state) =>
    state?.homeReducer?.totalSegmentDetailCount

export const _segmentContent = (state) => state?.homeReducer?.segmentContent
export const _totalHomeSegmentCount = (state) =>
    state?.homeReducer?.totalHomeSegmentCount
export const _totalPodcastSegmentCount = (state) =>
    state?.homeReducer?.totalPodcastSegmentCount
export const _totalRadioSegmentCount = (state) =>
    state?.homeReducer?.totalRadioSegmentCount
export const _totalAudiobookSegmentCount = (state) =>
    state?.homeReducer?.totalAudiobookSegmentCount
export const _totalAudioseriesSegmentCount = (state) =>
    state?.homeReducer?.totalAudioseriesSegmentCount
export const _totalLivestreamSegmentCount = (state) =>
    state?.homeReducer?.totalLivestreamSegmentCount

export const _radioImageLoaded = (state) => state?.homeReducer?.radioImageLoaded
